import { IRoutingStepperConfigItem } from '@/shared/routing-stepper/routing-stepper.interfaces';

export const signUpStepperConfig: IRoutingStepperConfigItem[] = [
  {
    routePath: '/sign-up/institution-info',
    label: 'Praxisname',
  },
  {
    routePath: '/sign-up/medical-specialties',
    label: 'Fachrichtungen',
  },
  {
    routePath: '/sign-up/credentials',
    label: 'Email & Passwort',
  },
  {
    routePath: '/order-subscription/licences',
    label: 'Idana bestellen',
  },
];

export const signUpStepperConfigWithoutOrder: IRoutingStepperConfigItem[] = [
  {
    routePath: '/sign-up/institution-info',
    label: 'Praxisname',
  },
  {
    routePath: '/sign-up/medical-specialties',
    label: 'Fachrichtungen',
  },
  {
    routePath: '/sign-up/credentials',
    label: 'Email & Passwort',
  },
];

export const orderSubscriptionStepperConfig: IRoutingStepperConfigItem[] = [
  {
    routePath: '/sign-up/credentials',
    label: 'Idana-Konto anlegen',
  },
  {
    routePath: '/order-subscription/licences',
    label: 'Anzahl Lizenzen',
  },
  {
    routePath: '/order-subscription/address',
    label: 'Rechnungsadresse',
  },
  {
    routePath: '/order-subscription/plan',
    label: 'Idana-Abo auswählen',
  },
  {
    routePath: '/order-subscription/onboarding',
    label: 'Einrichtungspaket',
  },
  {
    routePath: '/order-subscription/editor',
    label: 'Fragebogen-Editor',
  },
  {
    routePath: '/order-subscription/payment',
    label: 'Zahlungsmethode',
  },
  {
    routePath: '/order-subscription/summary',
    label: 'Zusammenfassung',
  },
];

export const orderFreeSubscriptionStepperConfig: IRoutingStepperConfigItem[] = [
  {
    routePath: '/sign-up/credentials',
    label: 'Idana-Konto anlegen',
  },
  {
    routePath: '/order-subscription/address',
    label: 'Adresse',
  },
  {
    routePath: '/order-subscription/free-plan-summary',
    label: 'Zusammenfassung',
  },
];
