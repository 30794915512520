

















































































































































import { Component, Vue } from 'vue-property-decorator';

import store from '@/store';
import { IdanaSubscriptionPlan } from '@/shared/interfaces';
import {
  getDiscountText,
  getIdanaSubscriptionPlanBenefits,
  getIdanaSubscriptionPlanPeriodHint,
  getIdanaSubscriptionPlanPrice,
  getSubscriptionPlanTotalCost,
} from './idana-subscription-info/idana-subscription-info.utils';

import { LocalStorage, StorageKeys } from '@/services/local-storage';

import IdanaSubscriptionInfo from './idana-subscription-info/IdanaSubscriptionInfo.vue';
import SubscriptionSelector from './components/SubscriptionSelector.vue';

@Component({
  components: { SubscriptionSelector, IdanaSubscriptionInfo },
})
export default class OrderSubscriptionPlan extends Vue {
  protected IdanaSubscriptionPlan = IdanaSubscriptionPlan;
  protected isLoading = false;

  protected get isMediumMobileDevice(): boolean {
    return store.getters['layoutModule/isMobileDeviceWidthExcludingLarge'];
  }

  protected get licencesCount(): number {
    return store.state.order.idanaSubscription.numberOfLicences;
  }

  protected get selectedSubscription(): IdanaSubscriptionPlan | null {
    return store.state.order.idanaSubscription.selectedSubscriptionPlan;
  }

  protected get subscriptionPlanWithDiscount(): IdanaSubscriptionPlan | null {
    return store.getters['order/subscriptionPlanWithDiscount'];
  }

  protected get discountPercent(): number {
    return store.getters['order/subscriptionDiscountPercent'];
  }

  protected get specialPlanPriceWithDiscount(): number {
    return store.getters['order/specialPlanPriceWithDiscount'];
  }

  protected get specialPlanDiscountMonthsDuration(): number {
    return store.getters['order/specialPlanDiscountMonthsDuration'];
  }

  protected get selectedPlanTotalCostWithDiscount(): number {
    return store.getters['order/selectedPlanTotalCostWithDiscount'];
  }

  protected get selectedPlanTotalCost(): number {
    return store.getters['order/selectedPlanTotalCost'];
  }

  protected get isPlanWithDiscountSelected(): boolean {
    return store.state.order.idanaSubscription.isPlanWithDiscountSelected;
  }

  protected get isDiscountAvailable(): boolean {
    return this.discountPercent > 0;
  }

  protected get isValid(): boolean {
    return !!this.selectedSubscription;
  }

  public get isStarterPlanSelected(): boolean {
    return (
      this.selectedSubscription === IdanaSubscriptionPlan.IDANA_STARTER_FLEXIBLE ||
      this.selectedSubscription === IdanaSubscriptionPlan.IDANA_STARTER
    );
  }

  public get savePercentage(): number {
    return this.isStarterPlanSelected ? 14 : 21;
  }

  mounted(): void {
    this.fetchCouponInfo().then(() => {
      if (this.isDiscountAvailable) {
        store.commit('order/isPlanWithDiscountSelected', true);
        this.selectSubscription(this.subscriptionPlanWithDiscount);
      } else {
        const selectedPlan = LocalStorage.getString(StorageKeys.SUBSCRIPTION_PLAN) as IdanaSubscriptionPlan;
        this.selectSubscription(selectedPlan);
      }
    });
  }

  protected beforeDestroy(): void {
    if (this.isValid) {
      store.dispatch('order/saveStateToLocalStorage');
    }
  }

  protected getSubscriptionPrice(plan: IdanaSubscriptionPlan | null): number {
    return getIdanaSubscriptionPlanPrice(store.state.order, plan);
  }

  protected getSubscriptionPlanTotalCost(plan: IdanaSubscriptionPlan): number {
    return getSubscriptionPlanTotalCost(store.state.order, plan);
  }

  protected getProductBenefitsList(plan: IdanaSubscriptionPlan | null): string[] {
    return getIdanaSubscriptionPlanBenefits(store.state.order.idanaSubscription.numberOfLicences, plan);
  }

  protected getDiscountText(): string {
    if (!this.subscriptionPlanWithDiscount) return '';

    return getDiscountText(
      this.subscriptionPlanWithDiscount,
      this.discountPercent,
      this.specialPlanDiscountMonthsDuration,
    );
  }

  protected getProductPeriodHint(plan: IdanaSubscriptionPlan | null): string {
    return getIdanaSubscriptionPlanPeriodHint(plan);
  }

  protected isProductSelected(plan: IdanaSubscriptionPlan | null, isProductWithDiscount = false): boolean {
    if (!plan) return false;
    return this.selectedSubscription === plan && this.isPlanWithDiscountSelected === isProductWithDiscount;
  }

  protected selectSubscription(plan: IdanaSubscriptionPlan | null): void {
    store.commit('order/selectedSubscriptionPlan', plan);
  }

  private async fetchCouponInfo(): Promise<void> {
    const couponId = store.state.order.idanaSubscription.stripeCouponId;
    const couponInfo = store.state.order.idanaSubscription.stripeCouponInfo;

    if (couponId && !couponInfo) {
      this.isLoading = true;
      await store.dispatch('order/fetchCouponInfo').finally(() => {
        this.isLoading = false;
      });
    }
  }
}
