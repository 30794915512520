



















import { Component, Vue } from 'vue-property-decorator';

import store from '@/store';

@Component({})
export default class OrderSubscriptionSuccess extends Vue {
  mounted(): void {
    store.dispatch('order/removeOrderStateFromStorage');
    store.dispatch('order/removeOrderSpecificInfoFromStorageAfterOrder');
  }

  protected get isFreeAccountMode(): boolean {
    return store.state.order.isFreeAccount;
  }

  protected get successText(): string {
    return this.isFreeAccountMode
      ? 'Ausgezeichnet! Ihr Idana-Konto ist freigeschaltet.'
      : 'Ihre Bestellung war erfolgreich!';
  }

  protected goToDashboard(): void {
    this.$router.push({ path: '/' });
  }
}
