






















import { Component, Vue } from 'vue-property-decorator';

import store from '@/store';
import { AuthServiceSingleton } from '@/services/auth/auth.service';
import { StripeServiceSingleton } from '@/services/stripe';
import { IIdanaSupportBarConfig } from '@/shared/idana-support-bar';
import {
  IRoutingStepperConfigItem,
  orderFreeSubscriptionStepperConfig,
  orderSubscriptionStepperConfig,
} from '@/shared/routing-stepper';

import RoutingStepper from '@/shared/routing-stepper/RoutingStepper.vue';
import SplitPageLayout from '@/shared/split-page-layout/SplitPageLayout.vue';
import AppFooter from '@/components/AppFooter.vue';

@Component({
  components: { RoutingStepper, SplitPageLayout, AppFooter },
})
export default class OrderSubscriptionRootPage extends Vue {
  protected supportInfo: IIdanaSupportBarConfig = {
    title: 'Sie haben Fragen?',
    phone: '0761 60067841',
  };

  protected get stepperValues(): IRoutingStepperConfigItem[] {
    return this.isFreeAccountMode ? orderFreeSubscriptionStepperConfig : orderSubscriptionStepperConfig;
  }

  private stripeService = StripeServiceSingleton;

  protected get isFreeAccountMode(): boolean {
    return store.state.order.isFreeAccount;
  }

  protected get sideNavTitle(): string {
    return this.isFreeAccountMode ? 'Idana testen' : 'Idana bestellen';
  }

  protected get sideNavText(): string {
    return this.isFreeAccountMode
      ? 'Testen Sie Idana unverbindlich und ohne Kosten.'
      : 'Individualisieren Sie Ihre Bestellung. Nach Abschluss können Sie gleich mit Idana starten.';
  }

  protected created(): void {
    if (!this.isFreeAccountMode && AuthServiceSingleton.isAuthenticated()) {
      this.initStripeElements();
    }
  }

  private initStripeElements() {
    if (!this.stripeService.isInitialized) {
      setTimeout(this.initStripeElements.bind(this), 10);
      return;
    }

    this.stripeService.initPaymentElement();
  }
}
